import * as React from "react";
import Layout from '../components/layout/layout';
import * as styles from '../components/about.module.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Keyfeatures from "../components/keyfeatures/keyfeatures";
import Contact from "../components/contactus/contactus";
import PayPreContest from '../images/payperContest.png';
import propmeimg from '../images/propmeabout.png';
import Plus from '../images/plus.svg';
import madden from '../images/madden.png';
import cars from '../images/cars.png';
import fortnite from '../images/fortnite.png'
import hugesuccess from '../images/hugeSuccess.webp';
import OurPartner1 from '../images/ourPartner1.png';
import OurPartner2 from '../images/ourPartner2.png';
import redFacebook from '../images/redFacebook.svg';
import redInsta from '../images/redInsta.svg';
import redTwitter from '../images/redTwitter.svg';
import minus from '../images/minus.svg';

class Index extends React.Component{
  constructor(props){
    super(props);
    this.state={
      showAnswer: [false, false, false, false, false, false],
    }
  }

  hideAnswer = (num) => {
    this.setState((prevState) => {
      const newItems = [...prevState.showAnswer];
      newItems[num] = !newItems[num];
      return {showAnswer: newItems};
    })
  }

  render(){
    return(
      <Layout>
      <div className={styles.aboutSection}>
        <div className={styles.aboutSub}>
          <div className={styles.headerDiv}>
            <text className={styles.featureHeader}>About Us</text>
          </div>
          <div className={styles.aboutcontent1}>
            <text className={styles.aboutSectionContent}><p>PropMe is a West Virginia-based innovative online social betting company. We bring peer-to-peer (P2P) betting platform to market.</p> <p>We want to create new user experiences for a new audience. Gaming and gambling can be mobile, easy, secure and community based. We bring new ways to play.</p> <p>We have over 50 years combined gaming and gambling experience in West Virginia.</p></text>
          </div>
          <div className={styles.aboutcontent2}>
            <text className={styles.aboutSection2}>It's time a WV company gets in on the action!</text>
            <text className={styles.aboutSectionContent}>West Virginia Company<br/>West Virginia Investors<br/> West Virginia Games<br/>West Virginia Server<br/>West Virginia Bank</text>
            <text className={styles.aboutSection2}>We'd Love To Have You Join Us. Let's Do This Together!</text>
          </div>
        </div>
      </div>
      <div className={styles.whypropme}>
        <text className={styles.propmeheading}>PropMe: A Peer-to-Peer Betting Marketplace</text>
          <iframe src="https://www.youtube.com/embed/Q7mqxPozukI" frameborder="0" allowfullscreen=""  className={styles.landingimg}></iframe>
        <div className={styles.whypropdescdiv}>
          <text className={styles.propmedesc}><p>PropMe truly stands out as a first-of-its-kind marketplace, built from the ground up to facilitate user-friendly P2P betting – no sportsbook, complicated odds, or DFS rosters. PropMe makes betting easy for new and casual gamblers, as well as seasoned players, through its use of straightforward proposition bets.</p> <p>PropMe is poised to unlock this largely untapped target audience in the US. To date, studies on the target audience for sports betting have centered on the DFS market, or very early adopters, primarily because of federal restrictions on gambling.</p> <p>PropMe's efforts are focused on providing exciting, easily-accessible gambling experiences to new players, for example, from eSports and non-traditional sports like drone racing or VR racing leagues.</p></text>
        </div>
        {/* </div> */}
      </div>
      <Keyfeatures/>
      <div className={styles.pickContest}>
        <div className={styles.pickContestSub1}>
          <text className={styles.propmeheading}>Pick'em Contests</text>
          <div className={styles.propmedescDiv}>
            <text className={styles.propmedesc}>Pick'em Contests is a new feature we launched this year designed for the casual gambler to participate in pick'em contests made up of custom props. We create contests on eSports, traditional and non-traditional sporting events, or any event.</text>
          </div>
          <text className={styles.payPreContest}>PAY PER CONTEST - Here's How It Works:</text>
          <text className={styles.payPreContestmobile}>PAY PER CONTEST<br/>- Here's How It Works:</text>
          <iframe src="https://www.youtube.com/embed/vjvKKfnYlWQ" frameborder="0" allowfullscreen=""  className={styles.landingimg}></iframe>
        </div>
        <div className={styles.whatDiv}>
          <div className="row">
            <div className="col-lg-4" id={styles.whatDivCol}>
              <div className={styles.whatsinit}>
                <text className={styles.whatsinitHeader}>What’s In It For You?</text>
              </div>
            </div>
            <div className="col-lg-8" id={styles.whatDivCol}>
              <div className={styles.propmeSubDiv2}>
                <text className={styles.propmedesc}>Pay-Per-Contest (PPC) service gives bar, restaurant, sports clubs or any entity a way to participate in mobile wagering. We’ll pay you a percentage of each contest based the number of contests and players. As our video shows, all you have to do is direct users to your location on our menu. We create the contest, grade the props and provide all the other services like collecting payment and paying out to the winners. It’s a new model. It’s a WIN – WIN.</text>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4" id={styles.whatDivCol}>
            <div className={styles.whatsinit}>
              <text className={styles.whatsinitHeader}>Frequently Asked Questions</text>
            </div>
          </div>
          <div className="col-lg-8" id={styles.whatDivCol}>
            <div className={styles.propmeSubDiv2}>
              <div className={styles.inputname} onClick={() => this.hideAnswer(0)}>
                <text>What's the catch?</text>
                <div className={styles.plusimgDiv}>
                  {
                    this.state.showAnswer[0] == false ?
                    <img className={styles.plusimg} src={Plus} alt="landingimg" />
                    :
                    <img className={styles.plusimg} src={minus} alt="landingimg" />
                  }
                </div>
              </div>
              {
                this.state.showAnswer[0] == true ?
                <div className={styles.answerDiv}>
                  <p>There is no catch. We make money when you make money. Simple. You will have full transparency on fees, payouts, etc.</p>
                </div>
                :
                null
              }
              <div className={styles.inputname} onClick={() => this.hideAnswer(1)}>
                <text>Why are you doing this?</text>
                <div className={styles.plusimgDiv}>
                  {
                    this.state.showAnswer[1] == false ?
                    <img className={styles.plusimg} src={Plus} alt="landingimg" />
                    :
                    <img className={styles.plusimg} src={minus} alt="landingimg" />
                  }
                </div>
              </div>
              {
                this.state.showAnswer[1] == true ?
                <div className={styles.answerDiv}>
                  <p>There are five casinos in WV, four of the five are owned by out-of-state companies.  There are over 2000 bars and restaurants in WV, including our team.  It's time to keep the money in WV.   PropMe has a strategic connection to leadership in the WV Restaurant and Bar Association and limited video lottery association. </p>
                </div>
                :
                null
              }
              <div className={styles.inputname} onClick={() => this.hideAnswer(2)}>
                <text>How much can you make through our PPC?</text>
                <div className={styles.plusimgDiv}>
                  {
                    this.state.showAnswer[2] == false ?
                    <img className={styles.plusimg} src={Plus} alt="landingimg" />
                    :
                    <img className={styles.plusimg} src={minus} alt="landingimg" />
                  }
                </div>
              </div>
              {
                this.state.showAnswer[2] == true ?
                <div className={styles.answerDiv}>
                  <p>We have a 10% and 20% revenue share model.  A bar can make between $16,000 and $250,000 per year, depending on the number of users and contests we run.</p>
                </div>
                :
                null
              }
              <div className={styles.inputname} onClick={() => this.hideAnswer(3)}>
                <text>Do I need any investment, equipment or staff?</text>
                <div className={styles.plusimgDiv}>
                  {
                    this.state.showAnswer[3] == false ?
                    <img className={styles.plusimg} src={Plus} alt="landingimg" />
                    :
                    <img className={styles.plusimg} src={minus} alt="landingimg" />
                  }
                </div>
              </div>
              {
                this.state.showAnswer[3] == true ?
                <div className={styles.answerDiv}>
                  <p>No. Once you sign up with PropMe, we are your mobile gaming application. Any reports or technical support will be provided.</p>
                </div>
                :
                null
              }
              <div className={styles.inputname} onClick={() => this.hideAnswer(4)}>
                <text>What do I have to do to sign up with PropMe?</text>
                <div className={styles.plusimgDiv}>
                  {
                    this.state.showAnswer[4] == false ?
                    <img className={styles.plusimg} src={Plus} alt="landingimg" />
                    :
                    <img className={styles.plusimg} src={minus} alt="landingimg" />
                  }
                </div>
              </div>
              {
                this.state.showAnswer[4] == true ?
                <div className={styles.answerDiv}>
                  <p>Simply sign with PropMe - we have different revenue sharing models and want to be your mobile gaming partner. Contact us at: <text className={styles.highlightlink}>admin@propmellc.com</text></p>
                </div>
                :
                null
              }
            </div>
          </div>
        </div>
      </div>
      <div className={styles.esports}>
        <text className={styles.featureHeader}>PropMe Loves E-Sports!</text>
        <div className={styles.esportsimgs}>
          <img src={madden} alt="madden" className={styles.sportimg} />
          <img src={cars} alt="madden" className={styles.sportimg} />
          <img src={fortnite} alt="madden" className={styles.sportimg} />
        </div>
        <div className={styles.sportsdesc}>
          <div className="row">
            <div className="col-lg-6" id={styles.whatDivCol}>
              <h1 className={styles.esportsheading}>Project Cars Virtual Reality Racing Evening – HUGE SUCCESS!!</h1>
              <p>Our virtual reality racing event was a huge success! We had over a hundred drivers onsite and thousands participated by either watching onsite or online.</p>
              <p>Thank you to Travis Braden Racing who has partnered with PropMe and SaberVR (<text className={styles.highlightlinks}>@SaberVR</text>) to promote our Virtual Reality racing events and competitions throughout the state. Our virtual reality partner SaberVR was a huge hit and their technology was definitely one-of-a-kind for the state of WV.</p>
              <p>The event also allowed PropMe, SaberVR, and Braden Racing to form exclusive partnerships to promote and rollout VR racing events in the state of WV. With PropMe’s <text className={styles.highlightlinks}>WV Bar and Restaurant endorsement</text>, this will bring new technology to the state and new revenue opportunities for all parties, include the bar and restaurant owners.</p>
            </div>
            <div className="col-lg-6" id={styles.whatDivCol}>
              <img className={styles.hugesuccess} src={hugesuccess} alt="hugesuccess" />
            </div>
          </div>
        </div>
        <div className={styles.moreinfo}>
          <p>More to follow on the rollout…stay tuned! </p>
          <p>Thank you to Cody Tomer - from the The Intelligencer - Wheeling News Register for covering the event. </p>
          <p className={styles.article}>Article here. </p>
          <p>If you'd like to have us run a VR racing event for your bar, restaurant, or event please contact us at: <text className={styles.highlightlinks}>admin@propmellc.com</text></p>
        </div>
      </div>
      <div className={styles.ourPartners}>
        <div className={styles.headerDiv}>
          <text className={styles.featureHeader}>Our Partners</text>
          <div className={styles.ourPartnersRow}>
            <div className="row">
              <div className="col-lg-6" id={styles.whatDivCol}>
                <div className={styles.partners1}>
                  <img className={styles.partners1Img} src={OurPartner1} alt="hugesuccess" />
                  <text className={styles.partners1header}>SaberVR</text>
                  <div className={styles.imgsDiv}>
                    <img className={styles.redImg} src={redFacebook} alt="hugesuccess" />
                  </div>
                </div>
              </div>
              <div className="col-lg-6" id={styles.whatDivCol}>
                <div className={styles.partners1}>
                  <img className={styles.partners1Img} src={OurPartner2} alt="hugesuccess" />
                  <text className={styles.partners1header}>Travis Braden</text>
                  <div className={styles.imgsDiv}>
                    <img className={styles.redImg} src={redFacebook} alt="hugesuccess" />
                    <img className={styles.redImg} src={redInsta} alt="hugesuccess" />
                    <img className={styles.redImg} src={redTwitter} alt="hugesuccess" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Contact/>
    </Layout>
    )
  }
}

export default Index
