// extracted by mini-css-extract-plugin
export var aboutSection = "about-module--aboutSection--3Cku6";
export var featureHeader = "about-module--featureHeader--2Px2-";
export var aboutSectionContent = "about-module--aboutSectionContent--2rXMC";
export var aboutcontent1 = "about-module--aboutcontent1--2_XDC";
export var aboutcontent2 = "about-module--aboutcontent2--3t_yM";
export var aboutSection2 = "about-module--aboutSection2--2NQWN";
export var whypropme = "about-module--whypropme--1-MEv";
export var propmeheading = "about-module--propmeheading--2r0BY";
export var landingimg = "about-module--landingimg--3Rrv2";
export var propmedesc = "about-module--propmedesc--133IU";
export var propmedescDiv = "about-module--propmedescDiv--qS7aq";
export var propmeSubDiv2 = "about-module--propmeSubDiv2--3u_0q";
export var whypropdescdiv = "about-module--whypropdescdiv--gZ8nM";
export var pickContest = "about-module--pickContest--32K1E";
export var pickContestSub1 = "about-module--pickContestSub1--3yTLv";
export var payPreContest = "about-module--payPreContest--1lteQ";
export var pickContestHeader = "about-module--pickContestHeader--30Dab";
export var whatsinit = "about-module--whatsinit--gj4La";
export var whatsinitHeader = "about-module--whatsinitHeader--aNrkL";
export var whatDiv = "about-module--whatDiv--2Djqj";
export var inputname = "about-module--inputname--iU470";
export var plusimg = "about-module--plusimg--NMWaj";
export var plusimgDiv = "about-module--plusimgDiv--3hYC3";
export var ourPartners = "about-module--ourPartners--1Xo93";
export var partners1 = "about-module--partners1--vJps6";
export var partners1Img = "about-module--partners1Img--ueGSr";
export var imgsDiv = "about-module--imgsDiv--31HFV";
export var redImg = "about-module--redImg--2_uY0";
export var esports = "about-module--esports--Z8tXh";
export var esportsimgs = "about-module--esportsimgs--2LW4V";
export var sportimg = "about-module--sportimg--3yQ5j";
export var esportsheading = "about-module--esportsheading--76Nam";
export var sportsdesc = "about-module--sportsdesc--2vpBl";
export var hugesuccess = "about-module--hugesuccess--1o4KV";
export var moreinfo = "about-module--moreinfo--WsIJ6";
export var article = "about-module--article--d_NoZ";
export var highlightlinks = "about-module--highlightlinks--30vib";
export var partners1header = "about-module--partners1header--gZ--S";
export var answerDiv = "about-module--answerDiv--2A8U9";
export var highlightlink = "about-module--highlightlink--1vvkQ";
export var payPreContestmobile = "about-module--payPreContestmobile--1acvU";
export var aboutSub = "about-module--aboutSub--3bMnq";
export var whatDivCol = "about-module--whatDivCol--34ArU";